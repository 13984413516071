import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePhysicianImportItemList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Nome' },
    { key: 'cpf', label: 'CPF' },
    { key: 'email', label: 'email' },
    { key: 'birthdate', label: 'Nascimento' },
    { key: 'crm', label: 'crm' },
    { key: 'crm_state', label: 'Estado crm' },
    { key: 'cnpj', label: 'CNPJ' },
    { key: 'cnpj_name', label: 'Razão social' },
    { key: 'phone', label: 'Telefone' },
    { key: 'status', label: 'STATUS', formatter: status => (status === 'success' ? 'Importado' : 'Erro') },
    { key: 'message', label: 'Mensagem' },
  ]
  const perPage = ref(25)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('status')
  const isSortDirDesc = ref(false)
  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const getReportParams = () => ({
    id: router.currentRoute.params.id,
    q: searchQuery.value,
    perPage: perPage.value,
    page: currentPage.value,
    sortBy: sortBy.value,
    sortDirection: isSortDirDesc.value ? 'desc' : 'asc',
  })

  const fetchPhysicians = (ctx, callback) => {
    store
      .dispatch('app-physician/fetchPhysicianImportItems', getReportParams())
      .then(response => {
        const { data: users, meta } = response.data
        callback(users)
        totalUsers.value = meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar lista',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchPhysicians,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    refetchData,
  }
}
