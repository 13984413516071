<template>
  <div>
    <back-btn />
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Erro ao buscar lista
      </h4>
    </b-alert>

    <template v-if="userData">
      <b-card>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold">Arquivo</span>
                </th>
                <td class="pb-50">
                  {{ userData.original_name }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold">Caminho</span>
                </th>
                <td class="pb-50">
                  {{ userData.file_path }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold">Instituição</span>
                </th>
                <td class="pb-50">
                  {{ userData.institution.name }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold">Criado por</span>
                </th>
                <td class="pb-50">
                  {{ userData.created_by.name }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <span class="font-weight-bold">Criado em</span>
                </th>
                <td class="pb-50">
                  {{ formatDateTimeDDMMYYHHMM(new Date(userData.created_at)) }}
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <h4>Médicos</h4>
            <b-table
              ref="refUserListTable"
              :sort-by.sync="sortBy"
              class="position-relative"
              :items="fetchPhysicians"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              empty-text="Nenhum registro correspondente encontrado"
              :sort-desc.sync="isSortDirDesc"
            >
              <template #cell(cpf)="{ item }">
                <div class="text-nowrap">
                  {{ formatCpf(item.cpf) }}
                </div>
              </template>
              <template #cell(status)="{ item }">
                <b-badge pill :variant="item.status === 'error' ? 'light-danger' : 'light-success'">
                  {{ item.status === 'success' ? 'Importado' : 'Falha' }}
                </b-badge>
              </template>
              <template #cell(message)="{ item }">
                {{ item.message }}
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="
                  d-flex
                  align-items-center
                  justify-content-center justify-content-sm-start
                "
                >
                  <span class="text-muted">
                    Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas
                  </span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="
                  d-flex
                  align-items-center
                  justify-content-center justify-content-sm-end
                "
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalUsers"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BTable, BCard, BBadge, BPagination,
} from 'bootstrap-vue'
import BackBtn from '@core/components/navigation/BackBtn.vue'
import { formatCpf, formatDateTimeDDMMYYHHMM } from '@core/utils/utils'
import usePhysicianImportItemList from './usePhysicianImportItemList'
import userStoreModule from '../physicianStoreModule'

export default {
  components: {
    BPagination,
    BBadge,
    BCard,
    BTable,
    BRow,
    BCol,
    BAlert,
    BackBtn,
  },
  methods: {
    formatDateTimeDDMMYYHHMM,
    formatCpf,
  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-physician'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      fetchPhysicians,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
    } = usePhysicianImportItemList()

    store.dispatch('app-physician/fetchPhysicianImport', { id: router.currentRoute.params.id })
      .then(response => { userData.value = response.data.data })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData,
      fetchPhysicians,
      refUserListTable,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refetchData,
    }
  },
}
</script>

<style>

</style>
